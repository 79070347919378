import { EditorSDK } from '@wix/platform-editor-sdk';

import * as experiments from '../../utils/experiments';
import { setAboutPageBooleanParam, setMyAccountBooleanParam } from './members-area';

export const getCustomProfileBooleanSetting = (isEnabled: boolean) => ({
  key: 'customProfileEnabled',
  value: isEnabled,
});

export const maybeSetCustomProfileParams = async (editorSDK: EditorSDK) => {
  const isCustomProfileEnabled = await experiments.isCustomProfileEnabled();
  const settingsParam = getCustomProfileBooleanSetting(isCustomProfileEnabled);

  await setMyAccountBooleanParam(editorSDK, settingsParam);
  await setAboutPageBooleanParam(editorSDK, settingsParam);
};
